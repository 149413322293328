import React, { useEffect, useState, useRef } from 'react';
import './Desktop.css';

const Desktop = () => {
  // State variables
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [darkMode, setDarkMode] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const sections = useRef(['hero', 'features', 'app', 'newsletter']);
  
  // Header scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
      
      // Active section detection
      const currentPosition = window.scrollY + 200;
      for (const section of sections.current) {
        const element = document.getElementById(section);
        if (element) {
          const top = element.offsetTop;
          const height = element.offsetHeight;
          
          if (currentPosition >= top && currentPosition <= top + height) {
            setActiveSection(section);
            break;
          }
        }
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Smooth scrolling for anchor links
  useEffect(() => {
    const handleAnchorClick = (e) => {
      const target = e.target.closest('a[href^="#"]');
      if (!target) return;
      
      e.preventDefault();
      const element = document.querySelector(target.getAttribute('href'));
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 80,
          behavior: 'smooth'
        });
      }
    };
    
    document.addEventListener('click', handleAnchorClick);
    return () => document.removeEventListener('click', handleAnchorClick);
  }, []);

  // Effect for initial dark mode setting and system preference changes
  useEffect(() => {
    // Check system preference
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    
    // Set dark mode state based on system preference
    setDarkMode(prefersDarkMode);
    
    // Apply dark mode to document
    applyDarkMode(prefersDarkMode);
    
    // Listen for system preference changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const handleChange = (e) => {
      const newDarkMode = e.matches;
      setDarkMode(newDarkMode);
      applyDarkMode(newDarkMode);
    };
    
    // Add listener with browser compatibility
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handleChange);
    } else {
      mediaQuery.addListener(handleChange);
    }
    
    // Cleanup
    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', handleChange);
      } else {
        mediaQuery.removeListener(handleChange);
      }
    };
  }, []);
  
  // Function to apply dark mode consistently
  const applyDarkMode = (isDark) => {
    if (isDark) {
      document.documentElement.classList.add('dark-mode');
      document.body.classList.add('dark-mode');
    } else {
      document.documentElement.classList.remove('dark-mode');
      document.body.classList.remove('dark-mode');
    }
  };
  
  // Toggle dark mode function
  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    applyDarkMode(newDarkMode);
  };

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    // Prevent body scrolling when menu is open
    if (!mobileMenuOpen) {
      document.body.style.overflow = 'hidden';
      document.body.classList.add('mobile-menu-open');
    } else {
      document.body.style.overflow = '';
      document.body.classList.remove('mobile-menu-open');
    }
  };
  
  // Close mobile menu when clicking a link
  const handleMobileNavClick = (e) => {
    if (e.target.tagName === 'A') {
      setMobileMenuOpen(false);
      document.body.style.overflow = '';
      document.body.classList.remove('mobile-menu-open');
    }
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.elements.emailInput.value;
    
    try {
      const response = await fetch("https://api.hazirlan.ai/api/mail-list/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      
      if (response.ok) {
        setFormSuccess(true);
        setFormError('');
      } else {
        const data = await response.json();
        setFormError(data.errors?.email?.[0] || data.message || "Bir hata oluştu, lütfen tekrar deneyin.");
      }
    } catch (error) {
      setFormError("Bağlantı hatası. Lütfen daha sonra tekrar deneyin.");
    }
  };

  return (
    <>
      <div className={`site-wrapper ${darkMode ? 'dark-mode' : ''}`}>
        {/* Header */}
        <header className={`${isScrolled ? 'scrolled' : ''} ${darkMode ? 'dark-mode' : ''}`}>
          <div className="container header-container">
            <img src="/logo@2x.png" alt="hazirlan.ai logo" className="logo" />
            
            {/* Desktop navigation */}
            <div className="nav-links">
              <a href="#features" className={`nav-link ${activeSection === 'features' ? 'active' : ''}`}>Özellikler</a>
              <a href="#app" className={`nav-link ${activeSection === 'app' ? 'active' : ''}`}>Uygulama</a>
              <a href="#newsletter" className={`nav-link btn btn-primary ${activeSection === 'newsletter' ? 'active' : ''}`}>Haberdar Ol!</a>
            </div>
            
            {/* Dark mode toggle - only visible on desktop */}
            <button 
              onClick={toggleDarkMode} 
              className="dark-mode-toggle desktop-only"
              aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
            >
              {darkMode ? "☀️" : "🌙"}
            </button>
            
            {/* Mobile menu button */}
            <div 
              className={`mobile-menu-btn ${mobileMenuOpen ? 'active' : ''}`}
              onClick={toggleMobileMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </header>
        
        {/* Mobile Menu */}
        <div className={`menu-overlay ${mobileMenuOpen ? 'visible' : ''}`} onClick={toggleMobileMenu}></div>
        <nav className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
          <a href="#features" className="mobile-nav-link" onClick={handleMobileNavClick}>Özellikler</a>
          <a href="#app" className="mobile-nav-link" onClick={handleMobileNavClick}>Uygulama</a>
          <a href="#newsletter" className="mobile-nav-link btn btn-primary" onClick={handleMobileNavClick}>Haberdar Ol!</a>
          
          {/* Theme toggle in mobile menu */}
          <div className="mobile-theme-toggle">
            <span className="label">Tema</span>
            <button 
              onClick={toggleDarkMode} 
              className="toggle-btn"
              aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
            >
              {darkMode ? "☀️" : "🌙"}
            </button>
          </div>
          
          <div className="social-links mobile-social">
            <a href="https://www.instagram.com/hazirlan.ai/" target="_blank" rel="noopener noreferrer" className="social-link">
              <img src="/social-icons-2.svg" alt="Instagram" className="social-icon" />
            </a>
            <a href="https://www.linkedin.com/company/hazirlan-ai/" target="_blank" rel="noopener noreferrer" className="social-link">
              <img src="/social-icons-3.svg" alt="LinkedIn" className="social-icon" />
            </a>
          </div>
        </nav>
        
        {/* Hero Section */}
        <section id="hero" className="hero">
          <div className="container hero-container">
            <div className="hero-content">
              <h1 className="hero-title">Yapay Zeka Destekli Matematik Soru Bankası</h1>
              <h2 className="hero-subtitle">TYT ve AYT için <strong>hemen <em>hazırlan!</em></strong></h2>
              <div className="btn-group">
                <a href="#features" className="btn btn-primary">Özellikleri Keşfet</a>
              </div>
              <div className="store-badges">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img src="/group.svg" alt="App Store" className="store-badge" />
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img src="/googleplay.svg" alt="Google Play" className="store-badge" />
                </a>
              </div>
            </div>
            <div className="hero-image">
              <img src="/top_part.png" alt="hazirlan.ai app preview" />
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="features">
          <div className="container">
            <div className="section-title">
              <h2>Neden hazırlan.ai?</h2>
              <p>Yapay zeka teknolojisiyle sınava hazırlanmanın en akıllı yolu</p>
            </div>
            <div className="features-grid">
              <div className="feature-card">
                <img src="/computer@2x.png" alt="Multi-device" className="feature-icon" />
                <h3 className="feature-title">İstediğin Cihaz</h3>
                <p className="feature-description">İster tabletinle, ister telefonunla çalış. Ortama takılı kalma.</p>
              </div>
              <div className="feature-card">
                <img src="/digitalization@2x.png" alt="AI Technology" className="feature-icon" />
                <h3 className="feature-title">Yapay Zeka</h3>
                <p className="feature-description">Tüm sorular senin profiline göre yapay zekayla önüne çıkıyor.</p>
              </div>
              <div className="feature-card">
                <img src="/communication@2x.png" alt="Question Bank" className="feature-icon" />
                <h3 className="feature-title">Binlerce Soru</h3>
                <p className="feature-description">Sınav sistemine uygun, binlerce modern tipte soru.</p>
              </div>
            </div>
          </div>
        </section>

        {/* App Showcase 1 */}
        <section id="app" className="app-showcase">
          <div className="container showcase-container">
            <div className="showcase-content">
              <h3 className="showcase-title">Kişiselleştirilmiş Müfredat</h3>
              <p className="showcase-description">Gelişmiş yapay zekayla hazırladığımız müfredatımızla zayıf olduğun konularda gelişmeni sağlıyoruz.</p>
              {/* Removed the "Daha Fazla Bilgi" button */}
            </div>
            <div className="showcase-image">
              <img src="/iphone-12-pro.png" alt="Personalized curriculum" loading="lazy" />
            </div>
          </div>
        </section>

        {/* App Showcase 2 */}
        <section className="app-showcase">
          <div className="container showcase-container reverse">
            <div className="showcase-content">
              <h3 className="showcase-title">Süre Bazlı Performans Analizi</h3>
              <p className="showcase-description">Akıllı sayaç özelliğiyle soru çözme hızını ölçüyor, konuya özel raporlarla seni sınav ortamına hazırlıyoruz.</p>
              {/* Removed the "Daha Fazla Bilgi" button */}
            </div>
            <div className="showcase-image">
              <img src="/iphone-13@2x.png" alt="Performance analysis" loading="lazy" />
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="cta">
          <div className="container">
            <h3 className="cta-title">Başarıyla ulaşmak için hemen <em>hazırlan!</em></h3>
            <p className="cta-description">Yapay zeka destekli kişiselleştirilmiş eğitim deneyimi ile sınavlara en verimli şekilde hazırlan.</p>
            <div className="store-badges" style={{ justifyContent: 'center' }}>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img src="/group.svg" alt="App Store" className="store-badge" />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img src="/googleplay.svg" alt="Google Play" className="store-badge" />
              </a>
            </div>
          </div>
        </section>

        {/* App Showcase 3 */}
        <section className="app-showcase">
          <div className="container showcase-container">
            <div className="showcase-content">
              <h3 className="showcase-title">Düzenli Denemeler</h3>
              <p className="showcase-description">Kapsamlı sınavlarla sınav atmosferine hazırlıyor, gelişimini sürekli takip ediyoruz.</p>
              {/* Removed the "Daha Fazla Bilgi" button */}
            </div>
            <div className="showcase-image">
              <img src="/iphone-15@2x.png" alt="Regular practice exams" loading="lazy" />
            </div>
          </div>
        </section>

        {/* App Showcase 4 */}
        <section className="app-showcase">
          <div className="container showcase-container reverse">
            <div className="showcase-content">
              <h3 className="showcase-title">Liderlik Tabloları</h3>
              <p className="showcase-description">Seviyene göre ligler oluşturuyor, akranlarının arasındaki yerini görmeni sağlıyoruz.</p>
              {/* Removed the "Daha Fazla Bilgi" button */}
            </div>
            <div className="showcase-image">
              <img src="/image-23@2x.png" alt="Leaderboards" loading="lazy" />
            </div>
          </div>
        </section>

        {/* Newsletter Section */}
        <section id="newsletter" className="newsletter">
          <div className="container newsletter-container">
            <h3 className="newsletter-title"><em>hazırlan.ai</em> bültenine abone olun!</h3>
            <p>Yeni özellikler ve güncellemelerden ilk sen haberdar ol.</p>
            {!formSuccess ? (
              <form className="newsletter-form" onSubmit={handleSubmit}>
                <input 
                  type="email" 
                  placeholder="E-mail adresiniz" 
                  className="newsletter-input" 
                  required 
                  id="emailInput" 
                  name="emailInput"
                />
                <button type="submit" className="btn btn-primary">Kaydol</button>
              </form>
            ) : (
              <div className="success-message">
                Teşekkürler, abone oldunuz!
              </div>
            )}
            {formError && (
              <div className="error-message">
                {formError}
              </div>
            )}
          </div>
        </section>

        {/* Footer */}
        <footer>
          <div className="container footer-container simplified-footer">
            <div className="footer-content">
              <img src="/logo@2x.png" alt="hazirlan.ai logo" className="footer-logo enlarged-logo" />
              <div className="footer-contact">
                <a href="mailto:info@hazirlan.ai" className="footer-email">info@hazirlan.ai</a>
              </div>
              <div className="social-links">
                <a href="https://www.instagram.com/hazirlan.ai/" target="_blank" rel="noopener noreferrer" className="social-link">
                  <img src="/social-icons-2.svg" alt="Instagram" className="social-icon" />
                </a>
                <a href="https://www.linkedin.com/company/hazirlan-ai/" target="_blank" rel="noopener noreferrer" className="social-link">
                  <img src="/social-icons-3.svg" alt="LinkedIn" className="social-icon" />
                </a>
              </div>
            </div>
          </div>
          <div className="copyright">
            <p>
              <em>hazırlan.ai</em> © 2024 Tüm hakları saklıdır.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Desktop;